/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// import '@/settings.scss'

// Composables
import { createVuetify } from "vuetify";
import { VList } from "vuetify/components";
import DayJsAdapter from "@date-io/dayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";

// Extend dayjs with the required plugins
dayjs.extend(customParseFormat);
// dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

import "@/typography.scss";
import { af } from "vuetify/locale";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  date: {
    adapter: DayJsAdapter,
    // locale: {
    //   locale: af,
    // },
  },
  // display: {
  //   mobileBreakpoint: "sm",
  //   thresholds: {
  //     xs: 0,
  //     sm: 340,
  //     md: 800,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },

  defaults: {
    VList: {
      indentSize: "0px",
      listIndentSize: "0px",
      density: "compact",
    },
    VTextField: {
      density: "compact",
      variant: "outlined",
      color: "primary",
    },
    VSelect: {
      density: "compact",
      variant: "outlined",
      color: "primary",
    },
    VAutocomplete: {
      density: "compact",
      variant: "outlined",
      color: "primary",
    },
    VSelectionControl: {
      density: "compact",
      size: 20,
    },
    VCheckboxBtn: {
      density: "compact",
      size: 20,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary:
            import.meta.env.VITE_APP_MODE == "development"
              ? "#1f1cc9"
              : import.meta.env.VITE_APP_MODE == "mock-staging"
              ? "#b913c2"
              : "#316A36",
          "primary-lighten-1": "#7EB97F",
          "primary-darken-1": "#165120",
          secondary: "#5CBBF6",
          background: "#E5E5E5",
          navBar: "#204523",
        },
      },
    },
  },
});
